<template>
  <div class="Partner_I">
    <div class="headNavs">
      <div class="headTilte">合伙人申请</div>
      <div class="headDetails">说明：包含用户姓名、身份证号码、联系电话、状态及申请时间</div>
    </div>

    <!-- 搜索导航 -->
    <div class="searchNav">
      <div class="NavLi">
        <div class="NavLiName">联系人：</div>
        <div class="NavLiIpt">
          <el-input v-model="search.username" placeholder="联系人"></el-input>
        </div>
      </div>
      <div class="NavLi">
        <div class="NavLiName">用户：</div>
        <div class="NavLiIpt">
          <el-input v-model="search.nickname" placeholder="用户"></el-input>
        </div>
      </div>
      <div class="NavLi">
        <div class="NavLiName">联系电话：</div>
        <div class="NavLiIpt">
          <el-input v-model="search.phone" placeholder="联系电话"></el-input>
        </div>
        <div class="NavLiQuery" style="margin-left:24px" @click="BtnSearch()">查询</div>
        <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
      <div class="NavLi" v-for="obj in 5" :key="obj"></div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入联系人">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="id" label="ID" width="50"  align="center"></el-table-column>
        <el-table-column prop="nickname" label="用户"  align="center">
          <template  #default="scope">
              <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="联系人"  align="center"></el-table-column> 
        <el-table-column prop="id_card" label="身份证号码" align="center"></el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="status" label="审核状态" align="center">
          <template  #default="scope">
            <span>{{ {10:'等待审核',20:'通过审核',30:'未通过审核'}[scope.row.status||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间"  align="center">
          <template  #default="scope">
             <span>{{ scope.row.createtime || formatDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="agreeAdd(scope.row)"
              >同意
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="refuseAdd(scope.row)"
              >拒绝
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      tableData:[],//总列表
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      //搜索参数
      search: {
        nickname: "",
        username: "",
        phone: "",
        keyword: "",
      },
    }
  },
  mounted() {
    var that = this
    that.loadTable();
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/member/partnerapply/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) { 
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          nickname: "",
          username: "",
          phone: "",
          keyword: "",
        }
      }
      that.loadTable();
    },
    //同意
    agreeAdd(data){
      var that = this
      let dataId = data.id
      axios({
        method: 'post',
        url: '/admin/member/partnerapply/agree',
        data: {
          id : data.id
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //拒绝
    refuseAdd(data){
      var that = this
      let dataId = data.id
      axios({
        method: 'post',
        url: '/admin/member/partnerapply/refuse',
        data: {
          id : data.id
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }
  }
}
</script>
 